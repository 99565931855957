<!--
 * @Descripttion: 课程分类
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-21 20:48:59
 * @LastEditors: cyy
 * @LastEditTime: 2022-06-17 11:55:08
 -->
<template>
  <div class="outer-wrap">
    <div class="course-sort" v-if="$route.name == 'courseClassification'">
      <div class="flex-center" style="justify-content:space-between">
        <div>
          <div
            class="newTimeButton"
            v-if="newBtn"
            @click="toggleShowSort('', '', 1)"
          >
            新建分类
          </div>
          <change-photoimg
            ref="popo"
            :size="2"
            @complete="completephoto"
            class="changePhotoimg"
          ></change-photoimg>
        </div>
        <div class="right flex-center">
          <el-input
            v-model="inputsearch"
            size="medium"
            clearable
            placeholder="输入名称搜索"
            @keyup.enter.native="toSearch"
          ></el-input>
          <el-button
            type="primary"
            class="ml20"
            size="medium"
            @click="toSearch"
          >
            搜索
          </el-button>
        </div>
      </div>
      <div>
        <pagination2
          :option="courseSortListPost"
          url="/courseSet/newGetSetList"
          ref="courseSortListBox"
        >
          <template slot-scope="scope">
            <div class="list_header">
              <div class="h_item" style=" width: 100px" v-if="!searching">
                序号
              </div>
              <div class="h_item" style="width: 22%;">
                分类
              </div>
              <div class="h_item" style="width: 21%; ">
                创建时间
              </div>
              <div class="h_item" style="flex:1;text-align: right;">
                操作
              </div>
            </div>
            <ul class="sort-list" v-if="scope.tableData.length > 0">
              <li
                class="sort-item"
                v-for="(item, index) in scope.tableData"
                :key="item.set_id"
              >
                <div class=" box-inner">
                  <div style="width:100px;" v-if="!searching">
                    <el-popover
                      placement="right"
                      :ref="`popover1${index}`"
                      width="410"
                      trigger="click"
                    >
                      <div class="contain">
                        排列至第
                        <el-input
                          @keyup.enter.native="setSort(item)"
                          v-model="rankNumber"
                          class="ml30 mr10"
                          style="width:100px"
                          placeholder="输入数字"
                          size="medium"
                          onkeyup="value = value.replace(/[^\d]/g, '')"
                        ></el-input>
                        位
                        <el-button
                          size="medium"
                          type="primary"
                          class="ml10 mr10"
                          @click="setSort(item)"
                        >
                          确定
                        </el-button>
                        <el-button type="" size="medium" @click="cancel1()">
                          取消
                        </el-button>
                      </div>
                      <div class="rank" slot="reference">
                        {{ item.sort }}
                        <i class="el-icon-edit" style="margin-left:4px"></i>
                      </div>
                    </el-popover>
                  </div>

                  <div class="box-left" style="width: 21%;">
                    <div>
                      <i
                        @click="
                          toggleChildMenu(
                            item.set_id,
                            item.children && item.children.length > 0
                          )
                        "
                        class="arrow cp"
                        v-if="item.children && item.children.length > 0"
                        :class="{
                          'rotate-status ': activePid.includes(
                            item.children[0].set_pid
                          ),
                        }"
                      ></i>
                    </div>
                    <i
                      @click="
                        toggleChildMenu(
                          item.set_id,
                          item.children && item.children.length > 0
                        )
                      "
                      :class="
                        item.children && item.children.length > 0 ? 'cp' : ''
                      "
                      class="sort-icon"
                      :style="`background-image:url(${item.icon})`"
                    ></i>
                    <span
                      :class="
                        item.children && item.children.length > 0 ? 'cp' : ''
                      "
                      :title="managerBtn ? '点击管理课程' : ''"
                      @click="
                        toggleChildMenu(
                          item.set_id,
                          item.children && item.children.length > 0
                        )
                      "
                    >
                      {{
                        item.set_name +
                          '(' +
                          (item.count ? item.count : '0') +
                          ')'
                      }}
                    </span>
                  </div>
                  <div class="time" style="width:20%">
                    {{ item.create_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                  </div>
                  <div class="box-right" style="flex:1">
                    <span
                      v-if="eidtBtn"
                      class="cp"
                      @click.stop="toggleShowSort('edit', item.set_id, 1)"
                    >
                      编辑
                    </span>
                    <span v-if="eidtBtn">|</span>
                    <span
                      class="cp"
                      v-if="newBtn"
                      @click.stop="toggleShowSort('', item.set_id, 1, 2)"
                    >
                      添加子分类
                    </span>
                    <span v-if="newBtn">|</span>
                    <el-button type="text" @click="torank1(item)">
                      内容管理
                    </el-button>
                    <span>|</span>

                    <el-popover
                      placement="right"
                      :ref="`popover1${index}`"
                      width="410"
                      trigger="click"
                    >
                      <div class="contain">
                        排列至第
                        <el-input
                          v-model="rankNumber"
                          @keyup.enter.native="setSort(item)"
                          class="ml10 mr10"
                          style="width:100px"
                          placeholder="输入数字"
                          size="medium"
                          onkeyup="value = value.replace(/[^\d]/g, '')"
                        ></el-input>
                        位
                        <el-button
                          type="primary"
                          size="medium"
                          class="ml10 mr10"
                          @click="setSort(item)"
                        >
                          确定
                        </el-button>
                        <el-button type="" size="medium" @click="cancel1()">
                          取消
                        </el-button>
                      </div>
                      <el-button slot="reference" type="text">
                        排序
                      </el-button>
                    </el-popover>

                    <span v-if="newBtn && delBtn">|</span>
                    <span
                      v-if="delBtn"
                      class="cp"
                      @click.stop="toggleShowDeletePop(item.set_id)"
                    >
                      删除
                    </span>
                  </div>
                </div>
                <ul
                  class="child-menu"
                  v-if="item.children && item.children.length > 0"
                  :class="{
                    'hidden-status': !activePid.includes(
                      item.children[0].set_pid
                    ),
                  }"
                >
                  <div style="background:#f5f5f5" class="ml30">
                    <li
                      class="child-item"
                      v-for="(item2, index2) in item.children"
                      :key="index2"
                    >
                      <div class=" box-inner" style="padding-left:4px">
                        <div style="width:100px;" v-if="!searching">
                          <el-popover
                            placement="right"
                            :ref="`popover2${item2.set_id}`"
                            width="410"
                            trigger="click"
                          >
                            <div class="contain">
                              排列至第{{ item.sort }} -
                              <el-input
                                v-model="rankNumber"
                                class="ml10 mr10"
                                @keyup.enter.native="setSort(item2)"
                                style="width:100px"
                                placeholder="输入数字"
                                size="medium"
                                onkeyup="value = value.replace(/[^\d]/g, '')"
                              ></el-input>
                              位
                              <el-button
                                type="primary"
                                size="medium"
                                class="ml10 mr10"
                                @click="setSort(item2)"
                              >
                                确定
                              </el-button>
                              <el-button
                                type=""
                                size="medium"
                                @click="cancel1()"
                              >
                                取消
                              </el-button>
                            </div>
                            <div class="rank" slot="reference">
                              {{ item2.sort }}
                              <i
                                class="el-icon-edit"
                                style="margin-left:4px"
                              ></i>
                            </div>
                          </el-popover>
                        </div>

                        <div class="box-left" style="width: 21%;">
                          <i
                            class="sort-icon"
                            :style="`background-image:url(${item2.icon})`"
                          ></i>
                          <span :title="managerBtn ? '点击管理课程' : ''">
                            {{
                              item2.set_name +
                                '(' +
                                (item2.count ? item2.count : '0') +
                                ')'
                            }}
                          </span>
                        </div>

                        <div class="time" style="width:20%">
                          {{
                            item2.create_time
                              | formatTimeStamp('yyyy-MM-dd hh:mm')
                          }}
                        </div>
                        <div class="box-right" style="flex:1">
                          <span
                            v-if="eidtBtn"
                            class="cp"
                            @click.stop="
                              toggleShowSort('edit', item2.set_id, 1, 1)
                            "
                          >
                            编辑
                          </span>
                          <span v-if="eidtBtn">|</span>

                          <el-button type="text" @click="torank2(item2)">
                            内容管理
                          </el-button>
                          <span>|</span>
                          <el-popover
                            placement="right"
                            :ref="`popover2${item2.set_id}`"
                            class="aa"
                            width="410"
                            trigger="click"
                          >
                            <div class="contain">
                              排列至第{{ item.sort }} -
                              <el-input
                                v-model="rankNumber"
                                class="ml10 mr10"
                                style="width:100px"
                                placeholder="输入数字"
                                @keyup.enter.native="setSort(item2)"
                                size="medium"
                                onkeyup="value = value.replace(/[^\d]/g, '')"
                              ></el-input>
                              位
                              <el-button
                                type="primary"
                                size="medium"
                                class="ml10 mr10"
                                @click="setSort(item2)"
                              >
                                确定
                              </el-button>
                              <el-button
                                type=""
                                size="medium"
                                @click="cancel1()"
                              >
                                取消
                              </el-button>
                            </div>
                            <el-button slot="reference" type="text">
                              排序
                            </el-button>
                          </el-popover>

                          <span v-if="eidtBtn && delBtn">|</span>
                          <span
                            v-if="delBtn"
                            class="cp"
                            @click.stop="toggleShowDeletePop(item2.set_id)"
                          >
                            删除
                          </span>
                        </div>
                      </div>
                    </li>
                  </div>
                </ul>
              </li>
            </ul>
          </template>
        </pagination2>
      </div>

      <div
        class="empty-status"
        :style="`height:${emptyStatusHeihgt}px`"
        v-show="isnodata"
      >
        <empty :isOld="false" :positionCenter="true" str="absolute"></empty>
      </div>

      <loading v-show="isLoading" leftPos="0"></loading>

      <!--新建/编辑分类弹窗-->
      <addSortPop
        v-if="isShowSort"
        @closeFn="toggleShowSort"
        @childClickFix="childClickFix"
        :editId="setId"
        @open="open"
        :activeType="activeType"
        :isChild="isChild"
        :photourl="photourl"
      ></addSortPop>

      <!--删除分类弹窗-->
      <CourseConfirm
        v-if="isShowDeletePop"
        title="删除分类"
        :isSmallPop="true"
        @cancelFn="toggleShowDeletePop"
        @save="getDeleteSet"
      >
        <h4 class="reset-txt">
          删除该分类，子分类与内容都会被删除，确定删除？
        </h4>
      </CourseConfirm>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import changePhotoimg from '@cm/base/changePhotoimg'
import addSortPop from '@/components/coursePop/addSortPop'
import CourseConfirm from '@/components/CourseConfirm'
import empty from '@/components/Empty'
import tool from '@ass/js/tool'
import API from '@/request/api'
import loading from '@/components/LoadGif'
import bg from '@/mixin/background'

export default {
  data() {
    return {
      // 正在搜索
      searching: false,

      // 搜索字段
      inputsearch: '',

      rankNumber: '',

      tableData: [],

      sortting: false,

      photourl: '',

      count: '', //抽屉标题的课程数

      courseSortListPost: {
        search: '',
      }, //分类主页的的分页post

      isChild: '',

      activePid: [],

      isShowSort: false, //显示新建/编辑弹窗

      isShowDeletePop: false, //删除弹窗

      menuList: [],

      setId: '',

      activeType: '',

      emptyStatusHeihgt: '',

      isLoading: false,

      isnodata: false,
    }
  },

  components: {
    addSortPop,
    CourseConfirm,
    empty,
    loading,
    changePhotoimg,
  },

  mixins: [bg],

  created() {
    this.emptyStatusHeihgt = window.screen.availHeight - 178
  },

  provide() {
    return {
      getSetList2: this.getSetList2,
    }
  },

  computed: {
    //新建
    newBtn() {
      return this.$store.state.roots.includes(190)
    },

    //编辑
    eidtBtn() {
      return this.$store.state.roots.includes(191)
    },

    //管理课程
    managerBtn() {
      return this.$store.state.roots.includes(192)
    },

    //删除
    delBtn() {
      return this.$store.state.roots.includes(193)
    },
  },

  methods: {
    // 排序
    async setSort(item) {
      if (!this.rankNumber) {
        this.$root.prompt('请输入序号！')
        return
      }
      await this.$http({
        url: '/courseSet/setSort',
        data: {
          set_pid: item.set_pid,
          set_id: item.set_id,
          sort: this.rankNumber,
        },
        successMsg: true,
      })
      this.$refs.courseSortListBox.reset()
      this.cancel1()
    },

    // 提交排序
    async submitSort() {
      const course_ids = this.tableData.map(item => item.course_id)
      await this.$http({
        url: '/courseSet/courseSetSort',
        data: {
          set_id: this.courseSortDialogPost.set_id,
          course_ids,
        },
      })
      this.$root.prompt({
        msg: '操作成功',
        type: 'success',
      })
      this.cancelSort()
    },

    // 列表数据搜索
    toSearch() {
      if (this.inputsearch) {
        this.searching = true
      } else {
        this.searching = false
      }
      this.courseSortListPost.search = this.inputsearch
    },

    // 第一级排序取消按钮
    cancel1() {
      document.body.click()
      this.rankNumber = ''
    },

    // 第一级内容管理跳转
    torank1(item) {
      this.$router.push({
        path: `/courseClassification/nextRank?set_id=${item.set_id}&set_name=${item.set_name}`,
      })
    },

    // 第二级内容管理跳转
    torank2(item) {
      this.$router.push({
        path: `/courseClassification/nextRank?set_id=${item.set_id}&set_name=${item.set_name}`,
      })
    },

    // 取消排序
    cancelSort() {
      this.sortting = false
      this.$refs.drawerRefBox.reset()
    },

    // 是否打开排序
    drawerclose() {
      this.sortting = false
    },

    // 添加的分类内容进入排序模式
    sort() {
      this.sortting = !this.sortting
    },

    completephoto(val) {
      this.photourl = val
    },

    open() {
      this.$refs.popo.changePicture()
    },

    // 切换显示二级菜单
    toggleChildMenu(id, flag) {
      // 没有子菜单时停止
      if (!flag) {
        return
      }
      // 已存在pid时删除
      if (this.activePid.includes(id)) {
        // console.log(123123)
        let deleteIndex = tool.contains(this.activePid, id)
        this.activePid.splice(deleteIndex, 1)
        return
      }
      this.activePid.push(id)
    },

    // 删除分类
    getDeleteSet() {
      const self = this
      self.$http({
        name: API.delCourseSet_Api.name,
        url: API.delCourseSet_Api.url,
        data: {
          set_id: self.setId,
        },
        callback({ code }) {
          if (code == 200) {
            self.$refs.courseSortListBox.reset()
            self.toggleShowDeletePop()
          }
        },
      })
    },

    // 获取分类列表
    getSetList2() {
      const self = this
      self.isLoading = true
      self.$http({
        name: API.getSetList_Api.name,
        url: API.getSetList_Api.url,
        data: {
          is_parent: 2,
        },
        callback({ code, data }) {
          if (code == 200) {
            self.isLoading = false
            self.isnodata = false
            self.menuList = data
            // console.log(data)
          }
        },
        error({ code }) {
          if (code == 204) {
            self.isLoading = false
            self.isnodata = true
            self.menuList = []
          }
        },
      })
    },

    childClickFix() {
      //1.3.9.8新需求,新建成功后刷新分页的数据
      this.$refs.courseSortListBox.reset()
    },

    // 切换显示 新建/编辑弹窗
    toggleShowSort(type = '', editId = '', actype, isChild = '') {
      if (type == 'edit') {
        this.setId = editId
      } else {
        this.setId = ''
      }
      if (isChild) {
        this.setId = editId
      }
      this.isChild = isChild
      this.activeType = actype
      this.isShowSort = !this.isShowSort
      // this.$refs.courseSortListBox.reset()
    },

    // 切换显示 删除 弹窗
    toggleShowDeletePop(delId = '') {
      if (delId) {
        this.setId = delId
      } else {
        this.setId = ''
      }
      this.isShowDeletePop = !this.isShowDeletePop
    },
  },
}
</script>
<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.35s;
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
}

::v-deep .el-dialog__footer {
  height: 50px;
  padding: 0 20px;
}

.outer-wrap {
  padding: 20px 20px;
  background: #f5f5f5;
}

.course-sort {
  padding: 20px;
  background: #fff;
  .changePhotoimg {
    position: absolute;
    top: 0;
    height: 32px !important;
    margin-left: 30px;
    // top: 0;
  }
  .newTimeButton {
    width: 100px;
    height: 36px;
    color: #ffffff;
    background: rgba(10, 162, 155, 1);
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #0aa29b;
    font-size: 14px;
    line-height: 36px;
  }
}

.list_header {
  background-color: #f5f5f5;
  height: 42px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  .h_item {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  }
}

.sort-list {
  padding-top: 15px;

  .box-inner {
    position: relative;
    display: flex;
    align-items: center;
    height: 42px;
    margin-bottom: 10px;
    padding: 0 0px 0 20px;
    &:hover {
      background: #f5f7fa;
    }
    .contain {
      display: flex;
      align-content: center;
      color: #333333;
    }
    .time {
      font-size: 14px;
      color: #333333;
    }
    .rank {
      display: flex;
      width: auto;
      align-items: center;
      display: inline-block;
      font-size: 14px;
      color: #333333;
    }

    .arrow {
      width: 10px;
      height: 11px;
      position: absolute;
      left: -15px;
      top: 10px;
      background: url('../../assets/img/1.1.2/ico_zksq.png') no-repeat
        center/100% 100%;
      transition: transform 0.2s;
    }

    .rotate-status {
      transform: rotate(90deg);
    }

    span {
      padding-left: 10px;
      font-size: 14px;
    }

    .box-left,
    .box-right {
      display: flex;
      align-items: center;
    }

    .box-left {
      position: relative;
      span {
        color: #333;
      }

      .sort-icon {
        width: 28px;
        height: 28px;
        display: inline-block;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .box-right {
      justify-content: right;
      span {
        color: #0aa29b;
        margin: 8px;
      }
    }
  }

  .child-menu {
    display: block;
    .childAdd {
      color: #ccc !important;
      cursor: not-allowed !important;
    }
  }

  .hidden-status {
    display: none;
  }

  .child-item {
    padding-left: 30px;
    &:hover {
      background: #f5f7fa;
    }
    .box-inner {
      padding-left: 10px;
    }
  }
}

.reset-txt {
  padding: 10px 20px 47px 20px;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  line-height: 24px;
}

.empty-status {
  background: #ffffff;
}
</style>
